export default {
  geral: {
    app: 'Retaguarda',
    vertical: 'Sistema',

    navegacao: {
      saude: 'Salud',
      idiomas: 'Idiomas',
      educacional: 'Educativo',
      servicos: 'Servicios',
      empresas: 'Compañías',
      usuarios: 'Usuarios',
      faturas: 'Facturas',
      mensalidades: 'Matrícula'
    },
    feedbacks: {
      arquivoEnviadoSucesso: '¡Documento cargado exitosamente!',
      arquivoEnviadoErro: '¡Error al enviar el archivo!',
      somenteUmArquivo: '¡Inserte solo un archivo!',
      insiraArquivo: '¡Inserta un archivo!',
      arquivoSQLObrigatorio: 'La extensión del archivo debe ser .sql'
    }
  },

  dashboard: {
    information: 'No hay nada por aqui :(',
    instruction: 'Registrar una empresa para generar datos en el tablero',
    categorias: {
      contas: 'Cuentas',
      licencas: 'Licencias',
      ativacoes: 'Activaciones',
      semContas: 'Sin cuentas',
      integracoes: 'Integraciones',
      semLicencas: 'Sin Licencias',
      semAtivacoes: 'Sin Activaciones',
      semIntegracoes: 'Sin Integraciones'
    }
  },

  cadastro: {
    empresas: {
      titulo: 'Compañías',
      novo: 'Nueva compañia',
      cadastradoSucesso: '¡Empresa registrada con éxito!',
      atualizadoSucesso: '¡Empresa actualizada con éxito!',
      removidoSucesso: 'Empresa eliminada correctamente!',
      subdominio: 'Subdominio',
      verticalMercado: 'Vertical de mercado',
      codigoCliente: 'Código de cliente',
      perfilCriacaoConta: 'Perfil de creación de cuenta',
      contatoAdmin: 'Contacto administrativo',
      emailAdmin: 'Correo electrónico administrativo',
      implantacao: 'Implantación',
      acessarEmpresa: 'Empresa de acceso',
      empresaEmProcessamento: 'Empresa en proceso de creación. ¡Sostener!',
      processamentoFalhou: '¡El proceso de construcción falló! Comuníquese con el Gerente de Producto.',
      tarifas: 'Tarifas',
      debugs: 'Debugs',
      dadosTecnicos: 'Datos técnicos',
      dadosIntegracao: 'Datos para la integración',
      quadroSocietario: 'Estructura corporativa',
      novoSocio: 'Añadir miembro',
      editarSocio: 'Editar miembro',
      tipoIntegracao: 'Tipo de integración',
      falhas: 'Fracasos',
      relacionamento: 'Relación',
      integracao: 'Integración',
      servicos: 'Servicios',
      valor: 'Valor',
      editarValor: 'Editar valor',
      valorServico: 'Valor del servicio',
      tarfifaAtualizadoSucesso: '¡Tasa actualizada con éxito!',
      servicosInformation: 'No hay nada por aqui :(',
      servicosInstruction: 'No hay servicios registrados para esta tarifa',
      nenhumaMensagemDebug: 'No se registran mensajes de depuración',
      mensagem: 'Mensaje',
      data: 'Fecha',
      nenhumaMensagemFalha: 'No se registra ningún mensaje de error',
      idInterno: 'Id interno',
      idExterno: 'Id externo',
      entidadeOrigem: 'Entidad de origen',
      entidadeDestino: 'Entidad de destino',
      nenhumRelacionamento: 'Sin relación registrada',
      detalhesFalha: 'Detalles de la falla',
      information: 'Detalles de falla',
      tenantName: 'Tenant',
      tenantId: 'TenantId',
      registryName: 'RegistryName',
      registryId: 'RegistryId',
      timestamp: 'Timestamp',
      retried: 'Retried',
      exceptions: 'Exceptions',
      exceptionType: 'ExceptionType',
      stackTrace: 'StackTrace',
      message: 'Message',
      source: 'Source',
      host: 'Host',
      machineName: 'MachineName',
      processName: 'ProcessName',
      processId: 'ProcessId',
      assembly: 'Assembly',
      assemblyVersion: 'AssemblyVersion',
      frameworkVersion: 'FrameworkVersion',
      massTransitVersion: 'MassTransitVersion',
      operatingSystemVersion: 'OperatingSystemVersion',
      faultMessageTypes: 'FaultMessageTypes',
      nenhumaException: 'Ninguno exception registrada',
      actions: 'Actions',
      retry: 'Retry',
      retryEfetuadoSucesso: 'Retry realizado con éxito',
      configuracoes: 'Ajustes',
      key: 'Key',
      value: 'Value',
      atributos: 'Atributos',
      endpoints: 'Endpoints',
      atributoAtualizadoSucesso: 'Atributo actualizado correctamente',
      method: 'Method',
      name: 'Nombre',
      uri: 'URI',
      endpointAtualizadoSucesso: 'Endpoint actualizado correctamente',
      somenteIntegracao: 'Solo integración',
      id: 'Id para la integración',
      hooks: 'Hooks',
      novoHook: 'Nuevo hook',
      evento: 'Evento',
      url: 'Url',
      metodo: 'Método',
      webhook: 'Webhook',
      hookCriadoSucesso: 'Hook creado con éxito',
      hookAtualizadoSucesso: 'Hook actualizado con éxito',
      hookRemovidoSucesso: 'Hook eliminado con éxito',
      headers: 'Headers',
      novoHeader: 'Nuevo header',
      chave: 'Llave',
      headerCriadoSucesso: 'Encabezado creado con éxito',
      headerAtualizadoSucesso: 'Header actualizado con éxito',
      headerRemovidoSucesso: 'Header eliminado con éxito',
      situacao: 'Situación',
      statusAtualizadoSucesso: 'Estado actualizado con éxito',
      migracao: 'Migración',
      clienteMigracao: 'Migración de clientes',
      migracaoFinal: 'Migración final',
      idReferenciaTenant: 'ID de referencia de la Tenant',
      arquivoBancoDeDados: 'Archivo de base de datos',
      transferenciaAtualizadoSucesso: 'Transferencia actualizada con éxito',
      dataInicialCobranca: 'Fecha de facturación inicial',
      backups: {
        baseDados: 'Base de datos',
        solicitacoesBackup: 'Solicitudes de respaldo',
        editarSolicitacaoBackup: 'Editar solicitud de respaldo',
        novaSolicitacao: 'Nueva solicitud',
        exclusaoBase: 'Eliminar de la base de datos',
        excluirBase: 'Eliminar base de datos',
        excluirBaseBtn: 'Eliminar base',
        novaSolicitacaoBackup: 'Nueva solicitud de respaldo',
        motivo: 'Razón',
        dataSolicitacao: 'Fecha de solicitud',
        situacaoSolicitacao: 'Estado de la solicitud',
        gerado: 'Copia de seguridad generada',
        pendente: 'Solicitud pendiente',
        recebidoCliente: 'Recibido por el cliente',
        nomeRecebedor: 'Nombre del destinatario',
        cadastradoSucesso: 'Solicitud registrada con éxito',
        atualizadoSucesso: 'Solicitud actualizada correctamente',
        valorNaoConfere: 'Los datos informados no coinciden. Por favor verifique.',
        baseDeletadaSucesso: 'Proceso completado con éxito',
        deletadoPor: 'Responsable de la exclusión',
        dataExclusao: 'Fecha de exclusión',
        confirmacaoDados: 'Confirmación de datos',
        situacaoContratual: 'Estado del contrato',
        mensagemExclusao: {
          titulo: '¡Atención!',
          conteudo1: 'Este proceso de exclusión de base es',
          irreversivel: 'irreversible.',
          conteudo2:
            'A medida que avanza, asegúrese de que se haya realizado la copia de seguridad y se haya enviado al cliente..'
        },
        dialogExclusao: {
          titulo: '¡Atención!',
          baseDe: 'La base de datos de la empresa:',
          seraExcluida: 'Será eliminado permanentemente.',
          processo: 'Este proceso es',
          irreversivel: 'irreversible ',
          usuarioRegistrado: 'y su usuario quedará registrado como responsable.',
          desejaProsseguir: 'Quieres proceder?'
        }
      },

      historicoContratacoes: {
        titulo: 'Historial de contratación',
        mesAtual: 'Mes actual',
        resumo: 'Resumen',
        movimentoUsuarios: 'Movimiento del usuario',
        quantidadeUsuarios: 'Número de usuarios',
        valorMensalidade: 'Cuota mensual',
        dataProfissionalSaude: 'Fecha / Profesional de la salud',
        emailUsuario: 'Correo electrónico / Usuario',
        nenhumaMovimentacaoEncontrada: 'No se encontraron unidades',
        dataAlteracao: 'Fecha de cambio',
        justificativaAlteracao: 'Justificación del cambio de tarifa',
        quantidadeAlteradaProfissionais: 'Se cambió el número de profesionales de "{{valueX}}" a "{{valueY}}".'
      },

      qtdeAlunos: 'Numero de estudiantes',
      qtdeMedicos: 'Numero de doctores',
      qtdeProfissionaisSaude: 'Número de profesionales de la salud',

      mensalidade: {
        valorPadraoPorProfissionalSaude: 'Valor por defecto por profesional sanitario',
        valorProfissionlSaudeAposContratacao:
          'Importe cobrado por profesional sanitario añadido tras la contratación inicial',
        valorNegociadoProfissionalSaude: 'Valor negociado por profesional de la salud',
        aposContratacaoInicial: 'Después de la contratación inicial',
        justificativaAlteracaoTarifaMensalidade: 'Justificación del cambio de tarifa/cuota mensual'
      }
    },

    integracoes: {
      titulo: 'Servicios',
      atualizadoSucesso: '¡Servicio actualizado con éxito!',
      integracaoAtiva: 'Integración activa',
      ultimaVerificacao: 'Última revisión',
      preTaxas: 'Pre tarifas',
      hooks: 'Hooks',
      nenhumHook: 'No se recibieron webhooks',
      data: 'Fecha',
      api: 'API',
      processado: 'Procesado',
      webhook: 'Webhook',
      tenantId: 'TenantId',
      tenant: 'Tenant',
      exceptions: 'Excepciones',
      exceptionType: 'ExceptionType',
      stackTrace: 'StackTrace',
      message: 'Mensaje',
      source: 'Fuente',
      host: 'Host',
      machineName: 'NombreDeLaMáquina',
      processName: 'NombreDelProceso',
      processId: 'ProcessId',
      assembly: 'Assembly',
      assemblyVersion: 'AssemblyVersion',
      frameworkVersion: 'FrameworkVersion',
      massTransitVersion: 'MassTransitVersion',
      operatingSystemVersion: 'OperatingSystemVersion',
      findedRelationship: 'Finded relationship',
      nomeEndpoint: 'Nombre endpoint',
      entidadeInterna: 'Entidad interna',
      idInterno: 'Id interno',
      idExterno: 'Id externo',
      fieldInterno: 'Campo interno',
      nenhumaExcecao: 'No se encontraron excepciones',
      nenhumRelacionamento: 'No se encontraron relaciones',
      novaIntegracaoAdicionada: '¡Nueva integración añadida!',
      desejaSalvarIntegracao: '¿Quieres guardar esta nueva integración y seguir adelante con el cliente?'
    },

    usuarios: {
      titulo: 'Usuarios',
      novo: 'Nuevo Usuario',
      cadastradoSucesso: '¡Usuario registrado exitosamente!',
      atualizadoSucesso: 'Usuario actualizado correctamente!'
    }
  },

  financeiro: {
    abertas: 'Abierto',
    fechadas: 'Cerrado',
    finalizadas: 'Terminado',
    liquidadas: 'Establecido',

    mensalidades: {
      tarifaPadraoMensalidadeAluno: 'Valor por alumno',
      tarifaPadraoMensalidadeMedico: 'Valor por doctor',
      tarifaPadraoMensalidade: 'Valor por alumno'
    },
    faturas: {
      fechamentoFatura: 'Cierre de factura',
      vencimentoFatura: 'Vencimiento de la factura',
      visivelNoSistema: 'Visible en el sistema'
    }
  }
}
