import React, { useMemo, Suspense, lazy } from 'react'

import { SptLoading } from '@sponte/lib-components/dist/atoms/Loading'

import { useAuth } from 'providers/auth'

const Auth = lazy(() => import('./pages/auth'))
const Admin = lazy(() => import('./pages/admin'))

const App = () => {
  const { eu } = useAuth()

  const Component = useMemo(() => {
    if (!eu) {
      return Auth
    }

    return Admin
  }, [eu])

  return (
    <Suspense fallback={<SptLoading full size="xl" position="absolute" />}>
      <Component />
    </Suspense>
  )
}

export default App
