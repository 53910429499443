import React, { Suspense } from 'react'

import { ReactQueryProvider } from 'providers/reactQuery'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter } from 'react-router-dom'
import { SnackbarProvider } from 'react-snackbar-alert'

import '@sponte/lib-utils/dist/yup-validations'

import createTheme from '@sponte/lib-themes/dist/educacional'
import { SptThemeProvider } from '@sponte/lib-themes/dist/ThemeProvider'

import { SptGlobalCss } from '@sponte/lib-components/dist/elements/GlobalCss'

import { SptLoading } from '@sponte/lib-components/dist/atoms/Loading'
import { SptSnackbar } from '@sponte/lib-components/dist/atoms/Snackbar'

import { API_URL, FEATURE_TOGGLES } from 'config'

import { extendApi } from 'api/retaguarda'

import i18n from 'i18n'

import * as authService from 'services/auth'

import { AuthProvider } from 'providers/auth'
import { FeatureTogglesProvider } from 'providers/featureToggles'
import { PreferencesProvider } from 'providers/preferences'

import { PermissionsProvider } from './permissions'

const addToken = (request) => {
  const token = authService.getToken()

  if (window.location.pathname) {
    const path = window.location.pathname
    const vertical = path.match(/educacional|saude|idiomas/g)
    if (vertical && vertical[0]) request.headers.set('x-vertical-sistema', vertical[0].toUpperCase())
  }

  if (token) request.headers.set('authorization', `Bearer ${token}`)
}

const refreshToken = async ({ request }) => {
  try {
    await authService.refreshToken()

    addToken(request)
  } catch (e) {
    authService.logout()

    window.location.reload()
  }
}

extendApi({
  prefixUrl: API_URL,
  hooks: {
    beforeRequest: [addToken],
    beforeRetry: [refreshToken]
  }
})

// configura o tema padrão do projeto
const theme = createTheme({
  logo: `/assets/logo.svg`,
  illustration: `/assets/illustration.svg`,
  notFound: `/assets/notFound.svg`,
  notFoundPage: `/assets/notFoundPage.svg`
})

const Providers = ({ children }) => {
  return (
    <FeatureTogglesProvider featureToggles={FEATURE_TOGGLES}>
      <SptThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <SnackbarProvider
            timeout={3000}
            sticky={false}
            dismissable={false}
            position="bottom-left"
            component={SptSnackbar}
          >
            <SptGlobalCss />

            <Suspense fallback={<SptLoading full size="xl" position="absolute" />}>
              <ReactQueryProvider>
                <AuthProvider
                  bootstrap={authService.bootstrap}
                  onLogin={authService.login}
                  onLogout={authService.logout}
                >
                  <PermissionsProvider>
                    <PreferencesProvider>
                      <BrowserRouter>{children}</BrowserRouter>
                    </PreferencesProvider>
                  </PermissionsProvider>
                </AuthProvider>
              </ReactQueryProvider>
            </Suspense>
          </SnackbarProvider>
        </I18nextProvider>
      </SptThemeProvider>
    </FeatureTogglesProvider>
  )
}

export default Providers
