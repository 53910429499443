import { getSafe } from '@sponte/lib-utils/dist/helpers/object'

export const APP_CONFIG = JSON.parse(getSafe(window, 'APP_CONFIG', ''))
delete window.APP_CONFIG

export const API_URL = getSafe(APP_CONFIG, 'API_URL', process.env.REACT_APP_API_URL)
export const APP_URL = getSafe(APP_CONFIG, 'APP_URL', process.env.REACT_APP_APP_URL)
export const FEATURE_TOGGLES = getSafe(
  APP_CONFIG,
  'FEATURE_TOGGLES',
  process.env.REACT_APP_FEATURE_TOGGLES || ''
).split(',')
